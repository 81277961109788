body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code a:link,
code a:visited {
    color: white;
}

code {
    font-family: 'JetBrains Mono', Menlo, Consolas, monospace;
    font-size: 12px;
    background: #FFFFFF33;
    padding: 4px 6px;
    border-radius: 4px;
    margin: 4px;
}

small {
    opacity: .75;
}

button {
    transition: opacity .25s !important;
}

[hidden] {
    pointer-events: none;
    opacity: .25;
}

p {
    margin: 0;
}

.disable-selection {
    user-select: none;
}
