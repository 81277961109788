.app {
    padding-top: 104px;
    width: 100%;
}

main {
    padding: 5rem 0;
    position: relative;
    width: 80%;
    max-width: 1080px;
    margin: 0 auto;
}

.content {
    margin-top: 2.5rem;
}

.editor {
    margin: 1rem 0;
}

.json-editor {
    margin-top: 1rem;
    border-radius: 6px;
    overflow: hidden;
    border: solid 1px #ddd;
}
